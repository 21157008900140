import { useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

// project-imports
import useAuth from "hooks/useAuth"

// types
import { GuardProps } from "types/auth"

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children, prefix }: GuardProps) => {
  const { isLoggedIn } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(`/`, {
        state: {
          from: location.pathname
        },
        replace: true
      })
    } 
  }, [isLoggedIn, navigate, location])

  return children
}

export default AuthGuard
